import FormElement from './FormElement';
import Input from './Input';
import {CSSStyles} from '../../../types/CSSStyles';

import React, {useRef} from 'react';
import {AriaTextFieldProps, useTextField} from 'react-aria';

export interface Props extends AriaTextFieldProps {
  readonly errorMessage?: React.ReactNode;
  readonly showValidIcon?: boolean;
  readonly style?: CSSStyles;
}

export default function TextInput(props: Props): JSX.Element {
  const {showValidIcon, style, ...ariaProps} = props;
  const {description, errorMessage, isDisabled, isRequired, label} = ariaProps;
  const ref = useRef(null);
  const {
    descriptionProps,
    errorMessageProps,
    inputProps,
    isInvalid,
    labelProps,
  } = useTextField(props, ref);

  return (
    <FormElement
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      isRequired={isRequired}
      label={label}
      labelProps={labelProps}
      sx={style}
    >
      <Input
        isInvalid={isInvalid}
        showValidIcon={showValidIcon}
        variant="input"
        {...inputProps}
      />
    </FormElement>
  );
}
