import {FormObject} from '../../../hooks/useForm';
import {ContentColor} from '../theme/useContentPalette';
import DetailsText from '../typography/DetailsText';

import React from 'react';

export interface Props<T extends Record<string, unknown>> {
  readonly formObject: FormObject<T>;
}

export default function FormErrors<T extends Record<string, unknown>>(
  props: Props<T>,
): JSX.Element {
  const {formObject} = props;

  return (
    <DetailsText color={ContentColor.ERROR}>
      {formObject.formErrors}
    </DetailsText>
  );
}
