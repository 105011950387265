import type {Errors} from '../errors/errors';

import {useCallback} from 'react';

export type RecordValidator<T extends Record<string, unknown>> = (input: T) => Errors<T>;

export default function useFormValidators<T extends Record<string, unknown>>(
  validators: Array<RecordValidator<Record<string, unknown>>>,
): (input: Record<string, unknown>) => Errors<T> {
  return useCallback(
    (input: Record<string, unknown>): Errors<T> => {
      let result: Errors<T> = {};
      for (const validator of validators) {
        result = {
          ...validator(input),
          ...result,
        };
      }
      return result;
    },
    [validators],
  );
}