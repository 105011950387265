import type {Errors} from '../errors/errors';

import FormSubmissionError from '../errors/FormSubmissionError';

import {isEmpty} from 'lodash';
import {useCallback, useState} from 'react';
import * as React from 'react';

export type FormConfig<TInput extends Record<string, unknown>> = Readonly<{
  // This is used when onSubmit or onValidate throw unexpected exceptions.
  fallbackFormErrors: Array<React.ReactNode>;
  // Throw FormSubmissionError if submission failed
  onSubmit: (input: TInput) => Promise<void>;

  startingValue: TInput;
  onStateChange?: (state: FormState) => unknown;
  onValidate?: (input: TInput) => Errors<TInput>;

  options?: {
    // If set to true, a form in success state will not be re-submitted. Default value: false.
    forbidSubmissionWhenSuccess?: boolean;
    // If set to true, the existing error of the changed field will be cleared. Default value: true.
    resetFieldErrorOnChange?: boolean;
    // Process the data before it is validated and submitted
    sanitizeInput?: (input: TInput) => TInput;
  };
}>;

export enum FormState {
  IDLE,
  VALIDATING,
  VALIDATED_INVALID,
  SUBMITTING,
  SUBMITTED_SUCCESS,
  SUBMITTED_ERROR,
}

export type FormObject<TInput extends Record<string, unknown>> = Readonly<{
  doSubmit: (e?: React.SyntheticEvent<HTMLFormElement>) => Promise<void>;
  fieldErrors: Readonly<Errors<TInput>>;
  fieldValues: Readonly<TInput>;
  formErrors: Array<React.ReactNode>;
  formState: FormState;
  resetForm: () => void;
  setFieldErrors: (errors: Errors<TInput>) => void;
  setFieldValue: <TKey extends keyof TInput>(
    key: TKey,
    value: TInput[TKey],
  ) => void;
  setFormErrors: (errors: Array<React.ReactNode>) => void;
}>;

export default function useForm<TInput extends Record<string, unknown>>({
  fallbackFormErrors,
  onStateChange,
  onSubmit,
  onValidate,
  options,
  startingValue,
}: FormConfig<TInput>): FormObject<TInput> {
  const {
    forbidSubmissionWhenSuccess = false,
    resetFieldErrorOnChange = true,
    sanitizeInput = (input: TInput) => input,
  } = options ?? {};
  const [formState, setFormStateImpl] = useState(FormState.IDLE);
  const setFormState = useCallback(
    (state: FormState) => {
      setFormStateImpl(state);
      onStateChange?.(state);
    },
    [onStateChange],
  );

  const [fieldValues, setFieldValues] = useState(startingValue);

  const [fieldErrors, setFieldErrors] = useState<Errors<TInput>>({});
  const [formErrors, setFormErrors] = useState<Array<React.ReactNode>>([]);

  const doSubmit = useCallback(
    async (e?: React.SyntheticEvent<HTMLFormElement>) => {
      if (e != null) {
        e.preventDefault();
      }

      if ([FormState.VALIDATING, FormState.SUBMITTING].includes(formState)) {
        return;
      }

      if (
        forbidSubmissionWhenSuccess &&
        formState === FormState.SUBMITTED_SUCCESS
      ) {
        return;
      }

      try {
        setFieldErrors({});
        setFormErrors([]);
        setFormState(FormState.VALIDATING);
        const finalInput = sanitizeInput(fieldValues);
        const errors = onValidate?.(finalInput);
        if (!isEmpty(errors)) {
          setFieldErrors((oldErrors) => ({...oldErrors, ...errors}));
          setFormState(FormState.VALIDATED_INVALID);
          return;
        }

        setFormState(FormState.SUBMITTING);
        await onSubmit(finalInput);
        setFormState(FormState.SUBMITTED_SUCCESS);
      } catch (err) {
        setFormState(FormState.SUBMITTED_ERROR);
        if (err instanceof FormSubmissionError) {
          setFieldErrors(err.getFieldErrors());
          setFormErrors(err.getFormErrors());
        } else {
          setFormErrors(fallbackFormErrors);
        }
      }
    },
    [
      fallbackFormErrors,
      fieldValues,
      forbidSubmissionWhenSuccess,
      formState,
      onSubmit,
      onValidate,
      sanitizeInput,
      setFormState,
    ],
  );

  const setFieldValue = useCallback(
    <TKey extends keyof TInput>(key: TKey, value: TInput[TKey]) => {
      if (resetFieldErrorOnChange) {
        setFieldErrors((errors) => {
          const newErrors = {...errors};
          if (key in errors) {
            delete newErrors[key];
          }
          return newErrors;
        });
      }

      setFieldValues((values) => {
        return {
          ...values,
          [key]: value,
        };
      });
    },
    [resetFieldErrorOnChange],
  );

  const resetForm = useCallback(() => {
    setFieldValues(startingValue);
    setFormState(FormState.IDLE);
  }, [setFormState, startingValue]);

  return {
    doSubmit,
    fieldErrors,
    fieldValues,
    formErrors,
    formState,
    setFieldErrors,
    setFieldValue,
    setFormErrors,
    resetForm,
  };
}
