import type {Errors} from '../errors/errors';

import ValidationError from '../validators/ValidationError';

import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

type Validator<T> = (input: T) => void;

export type RecordFieldValidator<T extends Record<string, unknown>> = (
  input: Record<string, unknown>,
) => Errors<T>;

export type Options = Readonly<{
  optional?: boolean;
}>;

export default function useRecordFieldValidator<
  T extends Record<string, unknown>,
  TKey extends keyof T,
>(
  key: TKey,
  validator: Validator<unknown>,
  options?: Options,
): RecordFieldValidator<T> {
  const {t} = useTranslation();
  return useCallback(
    (input: Record<string, unknown>): Errors<T> => {
      const {optional = false} = options ?? {
        optional: false,
      };
      try {
        if (!(key in input)) {
          return optional
            ? {}
            : ({
                [key]: t('validation.missing-required-field', {
                  name: key as string,
                }),
              } as Errors<T>);
        }
        validator(input[key as string]);
        return {};
      } catch (err) {
        if (err instanceof ValidationError) {
          return {
            [key]: err.message,
          } as Errors<T>;
        }
        return {[key]: t('validation.unknown-validation-error')} as Errors<T>;
      }
    },
    [key, options, validator],
  );
}
