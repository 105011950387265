import {FormObject, FormState} from '../../hooks/useForm';
import Button, {Props as ButtonProps} from '../design_system/Button';
import {ButtonSize} from '../design_system/Button/options';
import FormErrors from '../design_system/form/FormErrors';

import * as React from 'react';

type ButtonPropOmitKeys = 'children' | 'label' | 'to';

export interface Props<T extends Record<string, unknown>>
  extends Omit<ButtonProps, ButtonPropOmitKeys> {
  formObject: FormObject<T>;
  idleLabel: React.ReactNode;
  progressLabel: string;

  'data-testid'?: string;
  showFormError?: boolean;
}

export default function FormSubmitButton<T extends Record<string, unknown>>(
  props: Props<T>,
): JSX.Element {
  const {
    formObject,
    idleLabel,
    progressLabel: submittingLabel,
    showFormError = true,
    ...others
  } = props;
  const formIsInProgress = [
    FormState.VALIDATING,
    FormState.SUBMITTING,
  ].includes(formObject.formState);
  const shouldShowLoadingButton = formIsInProgress;
  if (shouldShowLoadingButton) {
    return (
      <Button
        isDisabled={true}
        label={submittingLabel}
        {...others}
      />
    );
  }

  return (
    <>
      <Button
        isDisabled={formIsInProgress}
        label={idleLabel}
        size={ButtonSize.LARGE}
        type="submit"
        {...others}
      />
      {showFormError && <FormErrors formObject={formObject} />}
    </>
  );
}
