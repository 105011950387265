import Flex from './flex/Flex';
import {CSSStyles} from '../../types/CSSStyles';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';
import white from '../design_system/theme/colors/white';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly children?: React.ReactNode;
  readonly sx?: CSSStyles;
}

export default function FormPageContentWrapper(props: Props): JSX.Element {
  const {children, sx} = props;
  const {spacing} = useAppTheme();
  return (
    <div
      css={[
        css({
          display: 'grid',
          gridTemplateColumns: '1fr minmax(360px, 700px) 1fr',

          [useMediaQuery(MediaQuery.ExtraSmall)]: {
            gridTemplateColumns: '0 auto 0',
          },
        }),
        sx,
      ]}
    >
      <div />
      <Flex
        sx={css({
          alignItems: 'center',
          backgroundColor: white.solid,
          height: 'max-content',
          borderRadius: '6px',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: 60,
          padding: '60px 80px 60px 80px',

          [useMediaQuery(MediaQuery.SmallScreen)]: {
            padding: spacing.x20,
            marginTop: spacing.x12,
          },
        })}
      >
        {children}
      </Flex>
      <div />
    </div>
  );
}
